import React from 'react';

function Gallery() {
  return (
    <section>
      <h2>Gallery</h2>
      <p>Photos coming soon!</p>
    </section>
  );
}

export default Gallery;
